import { Sendform } from '../libs/sendform/sendform2';
import { Slider } from '../libs/slider';
import '../../node_modules/select2/dist/js/select2.min.js';
//JQuery Mask
import '../libs/maskedinput.min.js';

$( document ).ready(function() {
    let projectApp = new App();
    global.app = projectApp;
    projectApp.init();
});

class App{
    // Define global visible variable inside app 
    constructor(){}
    init(){

        //Fixed header on mobile
        $(window).scroll(function(){
            let header = $('.header'),
                scroll = $(window).scrollTop()
            if (scroll >= 60) header.addClass('__fixed')
            else header.removeClass('__fixed')
        })

        //Scroll
        let $links = $('.js_scroll-lnk');
        $links.each(function() {
            $(this).click(function(){
                $links.each(function() {
                    $(this).removeClass('__active')
                })
                $(this).addClass('__active')
                $('.js_menu-cnt').removeClass('__show-menu')
                $('.js_menu-btn').removeClass('__active')
                $('html, body').animate({
                    scrollTop: $( $(this).attr('href') ).offset().top - 20
                }, 1000)
                return false
            })
        })

        //Select
        function formatText(icon) {
            return $(`<span class="select-blk"><span class="select-img" style="background-image: url(${$(icon.element).data('img')})"></span><span>${$(icon.element).text()}</span></span>`);
        }
        $('.js_select').each(function(){
            let $thisSelect = $(this)
            $thisSelect.select2({
                minimumResultsForSearch: Infinity,
                templateSelection: formatText,
                dropdownParent: $thisSelect.parent()
            })
        })

        //Send form
        new Sendform($('.js_send-form')[0], {
            msgValEachField: true,
            msgError: 'Sorry, cannot send this form',
            modalOpen: false,
            success: () => {
                $('.js_form-blk').addClass('__success')
                setTimeout(() => {
                    $('.js_form-blk').removeClass('__success')
                },10000)
            }
        })

        //Mobile menu
        $('body').on('click', '.js_menu-btn', function(e) {
            e.preventDefault()
            $(this).toggleClass('__active')
            if($(this).hasClass('__active')){
                $('.js_menu-cnt').addClass('__show-menu')
            } else{
                $('.js_menu-cnt').removeClass('__show-menu')
            }
        })

        //Slider for main block
        new Slider('.js_carousel-main',
            '.js_carousel-main-prev',
            '.js_carousel-main-next',
            true, {
                loop: true,
                margin: 0,
                items: 1,
                nav: false,
                autoHeight: false,
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                dots: false,
                paginationNumbers: true
        })
        //Slider about us
        new Slider('.js_carousel-about',
            '.js_carousel-about-prev',
            '.js_carousel-about-next',
            true, {
                items: 1.3,
                loop: true,
                margin: 10,
                nav: false,
                autoHeight: false,
                autoplay: false,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                dots: true,
                dotsEach: true,
                paginationNumbers: false,
                responsive:{
                    1025:{
                        items: 2.5,
                        dots: true,
                        margin: 15
                    },
                    681:{
                        items: 2,
                        dots: true,
                        margin: 15
                    }
                }
            })
    }
};
